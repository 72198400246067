import { useState, useEffect } from 'react';

type AnimationType = 'price' | 'percentage';

interface AnimatedNumberProps {
    value?: number;
    duration?: number;
    type?: AnimationType;
    prefix?: string;
    decimals?: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({
                                                           value = 3000,
                                                           duration = 1000,
                                                           type = 'price',
                                                           prefix = '$',
                                                           decimals = type === 'percentage' ? 1 : 0
                                                       }) => {
    const [displayValue, setDisplayValue] = useState<number>(0);

    useEffect(() => {
        const startTime = Date.now();
        const startValue = 0;

        const updateValue = () => {
            const currentTime = Date.now();
            const elapsed = currentTime - startTime;

            if (elapsed < duration) {
                const progress = elapsed / duration;
                // 使用 easeOutQuart 缓动函数让动画更加流畅
                const easeProgress = 1 - Math.pow(1 - progress, 4);
                const currentValue = startValue + (value - startValue) * easeProgress;
                setDisplayValue(currentValue);
                requestAnimationFrame(updateValue);
            } else {
                setDisplayValue(value);
            }
        };

        requestAnimationFrame(updateValue);
    }, [value, duration]);

    const formatValue = (val: number): string => {
        const formattedNum = val.toFixed(decimals);
        if (type === 'percentage') {
            return `${formattedNum}%`;
        }
        return `${prefix} ${Number(formattedNum).toLocaleString()}`;
    };

    return (
        <div className="text-3xl font-bold">
            {formatValue(displayValue)}
        </div>
    );
};

export default AnimatedNumber;