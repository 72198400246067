import "@rainbow-me/rainbowkit/styles.css";

import {darkTheme, lightTheme, RainbowKitProvider} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./screens/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { WalletConfig } from "./hooks/WalletConfig";

import "./i18n";
import Profile from "./screens/Profile";
import React from "react";
import Shop from "./screens/Shop";
import {Navigation} from "./Components/Navigation/navigation";
import {PRIMARY_COLOR} from "./utils/constants";
import Ido from "./screens/Ido";
import { createGlobalStyle } from 'styled-components';
import Finance from "./screens/Finance";
import Nft from "./screens/Nft";
import Dao from "./screens/Dao";
import Lending from "./screens/Lending";
import Meta from "./screens/Meta";
import Intro from "./screens/Intro"
import {IS_HOME_WEBSITE} from "./Global";
import NavbarHome from "./Components/NavbarHome";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
]);

const queryClient = new QueryClient();


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MyCustomFont';
    src: url('/fonts/fonts/glyphicons-halflings-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    background-color: #f6f6f6; /* 设置全局背景颜色 */
  }
`;

const App = () => {
  if(IS_HOME_WEBSITE){
    return (
        <>
          <GlobalStyle />
          <WagmiProvider config={WalletConfig}>
            <QueryClientProvider client={queryClient}>
              <RainbowKitProvider locale="en-US" theme={lightTheme({
                accentColor: PRIMARY_COLOR, // 你想要的按钮背景色
                accentColorForeground: '#ffffff', // 按钮文本颜色
                borderRadius: 'large', // 设置按钮的圆角
              })}>
                <Router>
                  <NavbarHome />
                  <Routes>
                    <Route element={<Intro />} path="/" />
                  </Routes>
                  <Footer />
                </Router>
              </RainbowKitProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <WagmiProvider config={WalletConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider locale="en-US" theme={lightTheme({
            accentColor: PRIMARY_COLOR, // 你想要的按钮背景色
            accentColorForeground: '#ffffff', // 按钮文本颜色
            borderRadius: 'large', // 设置按钮的圆角
          })}>
            <Router>
              <Navbar />
              <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<Ido/>} path="/ido"/>
                <Route element={<Finance/>} path="/finance"/>
                <Route element={<Nft/>} path="/nft"/>
                <Route element={<Dao/>} path="/dao"/>
                <Route element={<Lending/>} path="/lending"/>
                <Route element={<Meta/>} path="/meta"/>
              </Routes>
              <Footer />
            </Router>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
};

export default App;
