// ButtonWithImg.tsx
import styled from 'styled-components';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: string;
    children: React.ReactNode;
}

const StyledButton = styled.button`
  background: linear-gradient(90deg, #009524 0%, #a6c61a 100%);
  border: none;
  border-radius: 28px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    opacity: 0.9;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;

const ButtonWithImgColor: React.FC<ButtonProps> = ({ children, icon, ...props }) => {
    return (
        <StyledButton {...props}>
            {icon && <img src={icon} alt="" />}
            {children}
        </StyledButton>
    );
};

export default ButtonWithImgColor;