import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";


import useIsMobile from "../../hooks/useIsMobile";

import { getLang } from "../../utils/getLang";
import HomeTop from "./compoents/top";
import HomeArt from "./compoents/art";
import HomeToken from "./compoents/token";
import HomeNft from "./compoents/nft";
import HomeParent from "./compoents/parent";
import HomeMore from "./compoents/more";



const Intro = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
    <div style={{paddingTop:40,background:'#fff'}}>
        <HomeTop/>
        <HomeArt/>
        <HomeToken/>
        <HomeNft/>
        <HomeParent/>
        <HomeMore/>
    </div>
  );
};

export default Intro;
