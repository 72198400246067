import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";
import nftpic1 from "@/assets/img/intro/nft_2.png";
import nftpic2 from "@/assets/img/intro/nft_3.png";
import nftpic3 from "@/assets/img/intro/nft_4.png";


import "./art.less";


const HomeArt = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  const cardData = [
    {
      id: 1,
      title: t('card_title_1'),
      description: t('card_desc_1'),
      image: nftpic1
    },
    {
      id: 2,
      title: t('card_title_2'),
      description: t('card_desc_2'),
      image: nftpic2
    },
    {
      id: 3,
      title: t('card_title_3'),
      description: t('card_desc_3'),
      image: nftpic3
    }
  ];


  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
      <div>
        <div className="art-wrapper">
          <div className="art-header">
            <h1 className="art-title">{t('Intro_title')}</h1>
            <a href="#" className="art-learn-more">{t('LearnMore')} »</a>
          </div>
        </div>


        <div className="art-bg">
          <div className="art-wrapper">
            <div className="homenft-container">
              <div className="introart-container" ref={ref}>
                {cardData.map((card, index) => (
                    <div
                        key={card.id}
                        className={`introart-card ${isVisible ? 'introart-card-visible' : ''}`}
                        style={{
                          animationDelay: `${index * 200}ms` // 每个卡片延迟200ms出现
                        }}
                    >
                      <div className="introart-image-wrapper">
                        <img src={card.image} alt={card.title} className="introart-image" />
                      </div>
                      <div className="introart-content">
                        <h2 className="introart-title">{card.title}</h2>
                        <p className="introart-description">{card.description}</p>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

  );
};

export default HomeArt;
