import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";
import opepic from "@/assets/img/intro/token_ope.png";
import oplpic from "@/assets/img/intro/token_opl.png";
import carpic from "@/assets/img/common/car.png";


import "./token.less";
import ButtonWithImgColor from "../../../../Components/Button/ButtonWithImgColor";




const HomeToken = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  const tokenData = [
    {
      title: t('intro_token_title_1'),
      description: t('intro_token_desc_1'),
      price: '169.034',
      marketCap: '1.9M',
      circulation: '1.9M',
      pic: opepic
    },
    {
      title: t('intro_token_title_2'),
      description: t('intro_token_desc_2'),
      price: '169.034',
      marketCap: '1.9M',
      circulation: '1.9M',
      pic: oplpic
    }
  ];

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
    <div className="token-bg">
      <div className="introtoken-container">
        {tokenData.map((token, index) => (
            <div key={index} className="introtoken-card">
              <div className="introtoken-content">
                <div className="introtoken-left">
                  <h2 className="introtoken-title">{token.title}</h2>
                  <p className="introtoken-description">{token.description}</p>
                  <div className="introtoken-buttons">
                    <ButtonWithImgColor icon={carpic}>{t('BuyNow')}</ButtonWithImgColor>
                    <div className="introtoken-learn">{t('LearnMore')} →</div>
                  </div>
                </div>
                <div className="introtoken-right">
                  <img className="introtoken-image" src={token.pic}/>
                </div>
              </div>
              <div className="introtoken-metrics">
                <div className="introtoken-metric-box">
                  <span className="introtoken-value">$ {token.price}</span>
                  <span className="introtoken-label">{t('Price')}</span>
                </div>
                <div className="introtoken-metric-box">
                  <span className="introtoken-value">{token.marketCap}</span>
                  <span className="introtoken-label">{t('MarketCap')}</span>
                </div>
                <div className="introtoken-metric-box">
                  <span className="introtoken-value">{token.circulation}</span>
                  <span className="introtoken-label">{t('Liquidity')}</span>
                </div>
              </div>
            </div>
        ))}
      </div>
    </div>
  );
};

export default HomeToken;
