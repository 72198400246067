import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";
import logo1 from "@/assets/img/parents/1.png";
import logo2 from "@/assets/img/parents/2.png";
import logo3 from "@/assets/img/parents/3.png";
import logo4 from "@/assets/img/parents/4.png";
import logo5 from "@/assets/img/parents/5.png";
import logo6 from "@/assets/img/parents/6.png";


import "./parent.less";


const partnersList = [
  {
    image: logo1,
    link: 'https://coingecko.com'
  },
  {
    image: logo2,
    link: 'https://coinmarketcap.com'
  },
  {
    image: logo3,
    link: 'https://pancakeswap.finance'
  },
  {
    image: logo4,
    link: 'https://binance.com'
  },
  {
    image: logo5,
    link: 'https://bscscan.com'
  },
  {
    image: logo6,
    link: 'https://chainbow.com'
  }
];


const HomeParent = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
      <div className="parent-bg">
        <div className="introparent-container">
          <h1 className="introparent-title">{t('Parents_title')}</h1>
          <div className="introparent-grid">
            {partnersList.map((item, index) => (
                <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="introparent-card"
                >
                  <img src={item.image} alt="Partner logo" className="introparent-logo" />
                </a>
            ))}
          </div>
        </div>
      </div>
  );
};

export default HomeParent;
