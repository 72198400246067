import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

import "./homenav.less";
import AnimatedPrice from "../../../../Components/AnimatedPrice/AnimatedPrice";



const HomeNavContent = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  useEffect(() => {

  }, []);


  const [landingPageData, setLandingPageData] = useState({})



  return (
    <div className="homenav-wrapper">
      <div className="homenav-container">
        <h1 className="homenav-title">{t('Home_label1')}</h1>
        <h1 className="homenav-title2">{t('Home_label2')}</h1>
        <div className="floating-card">
          <div className="data-section">
            <div className="data-item">
              <p className="data-number"><AnimatedPrice type="price" prefix="$" value={321004} duration={1000}></AnimatedPrice></p>
              <p className="data-title">OPL TVL</p>
            </div>
            <div className="divider"></div>
            <div className="data-item">
              <p className="data-number">32.1%</p>
              <p className="data-title">OPL APY</p>
            </div>
            <div className="divider"></div>
            <div className="data-item">
              <p className="data-number">3.3%</p>
              <p className="data-title">OPE APY</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNavContent;
