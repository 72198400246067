import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";
import "./nft.less";
import nftpic1 from "@/assets/img/intro/nft_land.png";
import nftpic2 from "@/assets/img/intro/nft_house.png";
import nftpic3 from "@/assets/img/intro/nft_pic_soon.png";

const HomeNft = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

    const cardData = [
        {
            id: 1,
            title: t('intro_nft_title_1'),
            description: t('intro_nft_desc_1'),
            image: nftpic1
        },
        {
            id: 2,
            title: t('intro_nft_title_2'),
            description: t('intro_nft_desc_2'),
            image: nftpic2
        }
    ];

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
      <div className="intronft-bg">
        <div className="intronft-wrapper">
          <div className="intronft-header">
            <h1 className="intronft-title">NFT</h1>
          </div>
        </div>

        <div className="homenft-container" ref={ref}>
          <div className="intronft-container">
            {cardData.map((card, index) => (
                <div
                    key={card.id}
                    className={`intronft-card ${isVisible ? 'intronft-card-visible' : ''}`}
                    style={{
                      animationDelay: `${index * 200}ms`
                    }}
                >
                  <div className="intronft-image-wrapper">
                    <img src={card.image} alt={card.title} className="intronft-image" />
                  </div>
                  <div className="intronft-content">
                    <h2 className="intronft-title">{card.title}</h2>
                    <p className="intronft-description">{card.description}</p>
                  </div>
                </div>
            ))}

            <div
                key={3}
                className={`intronft-card ${isVisible ? 'intronft-card-visible' : ''}`}
                style={{
                  animationDelay: `${3 * 200}ms`,
                  padding: 0,
                  borderWidth: 0
                }}
            >
              <div className="intronft-image2">
                <div className="intronft-image2-text">
                    {t('CommingSoon')} »
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default HomeNft;