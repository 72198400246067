import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

import "./home.less";


import useIsMobile from "../../hooks/useIsMobile";

import { getLang } from "../../utils/getLang";
import HomeNavContent from "./compoents/homenav";
import HomeAssetsContent from "./compoents/homeassets";
import HomeNftList from "./compoents/nftlist";
import Homeproduct from "./compoents/homeproduct";
import Homeabout from "./compoents/homeabout";


const Home = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
    <div style={{paddingTop:80,background:'#fff'}}>

      <HomeNavContent/>
      <HomeAssetsContent/>
      <HomeNftList/>
      <Homeproduct/>
      <Homeabout/>
    </div>
  );
};

export default Home;
