import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import HOMEBG from "@/assets/img/h/home_bg.png";
import KLINE from "@/assets/img/h/kline.png";
import NFT_TEST from "@/assets/img/h/test_nft.png";
import "./homeassets.less";



const HomeAssetsContent = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  useEffect(() => {

  }, []);


  const [landingPageData, setLandingPageData] = useState({})

  return (
      <div className="homeassets-wrapper">
      <div className="homeassets-container">
        <h1 className="homeassets-title">{t('Home_assets')}</h1>

        <div className="homeassets-content">
          {/* Left Chart Section */}
          <div className="homeassets-chart-container">
            <img
                src={KLINE}
                alt="K-line chart"
                className="homeassets-chart-image"
            />

          </div>

          {/* Right Property Section */}
          <div className="homeassets-property-container">
            <img
                src={NFT_TEST}
                alt="Property view"
                className="homeassets-property-image"
            />

            <div className="homeassets-price-container">
              <div className="homeassets-price-item">
                <span className="homeassets-price-label">{t('FloorPrice')}</span>
                <span className="homeassets-price-value homeassets-price-down">
                1.9 OPL ↓
              </span>
              </div>
              <div className="homeassets-price-item">
                <span className="homeassets-price-label">{t('HighPrice')}</span>
                <span className="homeassets-price-value homeassets-price-up">
                2.0 OPL ↑
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default HomeAssetsContent;
