import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";
import enUS from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "./navbar.less";
import { getSignInInfo, getSignIn } from "../requests/index.js";
import { getLang } from "../utils/getLang";

import moreIcon from "@/assets/img/header/more.png";
import headerBon from "@/assets/img/header/header_bon.png";
import gou from "@/assets/img/header/gou.png";
import home from "@/assets/img/header/home.png";
import menuFinance from "@/assets/img/header/menu_finance.png"
import menuIdo from "@/assets/img/header/menu_ido.png"
import menuNft from "@/assets/img/header/menu_nft.png"
import menuRealestate from "@/assets/img/header/menu_realestate.png"
import menuLanguage from "@/assets/img/header/menu_language.png"

import whitepaper from "@/assets/img/header/whitepaper.png";
import shoppingmall from "@/assets/img/header/shoppingmall.png";
import linkwallet from "@/assets/img/header/linkwallet.png";
import login from "@/assets/img/header/login.png";
import download from "@/assets/img/header/download.png";
import line from "@/assets/img/header/line.png";
import logoImg from "@/assets/img/header/logo.png";
import langImg from "@/assets/img/header/lang.png";
import CIS from "@/assets/img/header/Checked_in_Successfully.png";
import CISCN from "@/assets/img/cnimg/Checked_in_SuccessfullyCn.png";
import juanone from "@/assets/img/header/juanone.png";
import juantwo from "@/assets/img/header/juantwo.png";
import my from "@/assets/img/header/my.png";
import langicon from "@/assets/img/header/langicon.png";
import logoone from "@/assets/img/header/logoone.png";
import close from "@/assets/img/home/close.png";
import ButtonWithImg from "./Button/ButtonWithImg";
import launchimg from "@/assets/img/common/launch.png";


dayjs.locale("en");

const PLANT_TYPE = process.env.REACT_APP_PLANT_TYPE;
const NavbarHome = () => {
  const { t, i18n } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const { address, isConnected } = useAccount();
  const lang = getLang();

  const [locale, setLocal] = useState(enUS);
  const changeLocale = (e: any) => {
    const localeValue = e.target.value;
    setLocal(localeValue);
    if (!localeValue) {
      dayjs.locale("en");
    } else {
      dayjs.locale("zh-cn");
    }
  };
  const onWaitAlert = () => {
    message.error({
      icon: (
        <img
          src={close}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        />
      ),
      content: "Coming soon",
    });
  };


  const langList = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "cn",
      label: "繁體中文",
    },
    {
      value: "ja",
      label: "日本語",
    },
    {
      value: "ko",
      label: "한국어",
    },
    {
      value: "ru",
      label: "Русский",
    },
    {
      value: "th",
      label: "ไทย",
    },
    {
      value: "vi",
      label: "Tiếng Việt",
    },
    {
      value: "km",
      label: "ភាសាខ្មែរ",  // 柬埔寨语
    },
    {
      value: "my",
      label: "မြန်မာစာ",  // 缅甸语
    },
    {
      value: "lo",
      label: "ພາສາລາວ",  // 老挝语
    }
  ];

  const [acLang, setAcLang] = useState(localStorage.getItem("lang") || "en");
  const [isShowLang, setIsShowLang] = useState(false);

  const [isShowMenu, setIsShowMenu] = useState(false);

  const switchLang = (val: string) => {
    i18n.changeLanguage(val);
    setAcLang(val);
    setIsShowLang(false);
    localStorage.setItem("lang", val);
    // window.location.reload();
  };

  // 菜单点击
  const menuHandler = () => {
    setIsShowMenu(false);
  };

  // 签到内容
  const [isShowCheckIn, setIsShowCheckIn] = useState(false);
  const [checkInNum, setCheckInNum] = useState(0);


  const checkInCancel = () => {
    setIsShowCheckIn(false);
  };
  const afterOpenChange = (open: boolean) => {
    if (!open) {
      document.body.classList.remove("modal_open");
    }
  };
  // 是否签到

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // 回到页面顶部
  const homeTop = () => {
    if (pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  // 选中的页面 / 首页 /shop 商城页面
  const [selectPage, setSelectPage] = useState("/");

  useEffect(() => {
    setSelectPage(pathname);
  }, [pathname]);

  // 滚动事件
  const handleScroll = () => {
    setIsShowMenu(false);
  };
  useEffect(() => {
    if (isShowMenu) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isShowMenu]);

  return (
    <div className="header">
      <div className="header_top">
        <Link to="/" className="logo" onClick={homeTop}>
          <img src={logoImg} alt="" className="logo_pc" />
          <img src={logoone} alt="" className="logo_mobile" />
        </Link>
        <div className="header_left">
          <ButtonWithImg icon={launchimg} onClick={()=>{window.location.href = "https://app.openas.ai"}}>
            {t('LaunchAPP')}
          </ButtonWithImg>
          <div
            className="lang_box_pc"
            onMouseEnter={() => setIsShowLang(true)}
            onMouseLeave={() => setIsShowLang(false)}
          >
            <div className="lang_box_item">
              <div className="lang" onClick={() => setIsShowLang(true)}>
                <img src={langicon} alt="" />
              </div>
            </div>
            <ul
              className={
                isShowLang
                  ? "lang_list lang_list_show"
                  : "lang_list lang_list_hide"
              }
            >
              {langList.map((item) => (
                <li
                  key={item.value}
                  className="lang_list_item"
                  onClick={() => switchLang(item.value)}
                >
                  {item.label}
                  <img
                    src={gou}
                    alt=""
                    className={
                      acLang === item.value
                        ? "lang_list_item_img_active"
                        : "lang_list_item_img"
                    }
                  />
                </li>
              ))}
            </ul>
          </div>

          <div
            className="more"
            onMouseEnter={() => setIsShowMenu(true)}
            onMouseLeave={() => setIsShowMenu(false)}
          >
            <img src={moreIcon} alt="" onClick={() => setIsShowMenu(true)} />
            <ul
              className={
                isShowMenu
                  ? "menu_list menu_list_show"
                  : "menu_list menu_list_hide"
              }
            >
              <li
                className="lang_box"
                onMouseEnter={() => setIsShowLang(true)}
                onMouseLeave={() => setIsShowLang(false)}
              >
                <div className="lang_box_item">
                  <img src={menuLanguage} alt="" />
                  <div className="lang" onClick={() => setIsShowLang(true)}>
                    <span>{t("Switch language")}</span>
                  </div>
                </div>
                <ul
                  className={
                    isShowLang
                      ? "lang_list lang_list_show"
                      : "lang_list lang_list_hide"
                  }
                >
                  {langList.map((item) => (
                    <li
                      key={item.value}
                      className="lang_list_item"
                      onClick={() => switchLang(item.value)}
                    >
                      {item.label}
                      <img
                        src={gou}
                        alt=""
                        className={
                          acLang === item.value
                            ? "lang_list_item_img_active"
                            : "lang_list_item_img"
                        }
                      />
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
};

export default NavbarHome;
