import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from "./cn.json";
import en from "./en.json";
import ja from "./ja.json";
import ko from "./ko.json";
import ru from "./ru.json";
import th from "./th.json";
import vi from "./vi.json";
import km from "./km.json";
import my from "./my.json";
import lo from "./lo.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      cn: {
        translation: cn,
      },
        ja: {
            translation: ja,
        },
        ko: {
            translation: ko,
        },
        ru: {
            translation: ru,
        },
        th: {
            translation: th,
        },
        vi: {
            translation: vi,
        },
        km: {
            translation: km,
        },
        my: {
            translation: my,
        },
        lo: {
            translation: lo,
        },
    },
    lng: localStorage.getItem("lang") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
