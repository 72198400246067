import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

import "./homenft.less";
import useIsMobile from "../../../../hooks/useIsMobile";
import TESTAVATAR from "@/assets/img/h/test_avatar.png";



const HomeNftList = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const isMobile = useIsMobile();

  const channel = query.get("channel");
  useEffect(() => {

  }, []);



  const nftData = [
    {
      id: 1,
      name: "Other Dead For Othe...",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 2,
      name: "Mutant Ape Yacht Club",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 3,
      name: "Broed Ape Yach Yiss",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 4,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 5,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 6,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 7,
      name: "Other Dead For Othe...",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 8,
      name: "Mutant Ape Yacht Club",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 9,
      name: "Broed Ape Yach Yiss",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 10,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 11,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
    {
      id: 12,
      name: "Moonbirds",
      floorPrice: "1.9 OPL",
      highestPrice: "2.0 OPL",
      image: "/api/placeholder/40/40"
    },
  ];

  if(isMobile){
    return(
        <div className="homenft-bg">
          <div className="homenft-container">
            <div className="homenft-header">
              <h1 className="homenft-title">{t('Home_nft')}</h1>
              <a href="#" className="homenft-learn-more">{t('LearnMore')} »</a>
            </div>

            <div className="homenft-content">
              <div className="homenft-rankings-container">
                <div className="homenft-rankings-column">
                  <div className="homenft-table-header">
                    <span className="homenft-header-ranking">{t('Ranking')}</span>
                    <span className="homenft-header-empty"></span>
                    <span className="homenft-header-name"></span>
                    <span className="homenft-header-floor">{t('FloorPrice')}</span>
                    <span className="homenft-header-highest">{t('HighPrice')}</span>
                  </div>
                  {nftData.slice(0, 6).map((item) => (
                      <div key={item.id} className="homenft-ranking-item">
                        <span className="homenft-rank">{item.id}</span>
                        <img src={TESTAVATAR} alt={item.name} className="homenft-nft-image" />
                        <span className="homenft-nft-name">{item.name}</span>
                        <span className="homenft-floor-price">{item.floorPrice}</span>
                        <span className="homenft-highest-price">{item.highestPrice}</span>
                      </div>
                  ))}
                </div>

                <div className="homenft-rankings-column">
                  <div className="homenft-table-header">
                    <span className="homenft-header-ranking">{t('Ranking')}</span>
                    <span className="homenft-header-empty"></span>
                    <span className="homenft-header-name"></span>
                    <span className="homenft-header-floor">{t('FloorPrice')}</span>
                    <span className="homenft-header-highest">{t('HighPrice')}</span>
                  </div>
                  {nftData.slice(6, 12).map((item) => (
                      <div key={item.id} className="homenft-ranking-item">
                        <span className="homenft-rank">{item.id}</span>
                        <img src={TESTAVATAR} alt={item.name} className="homenft-nft-image" />
                        <span className="homenft-nft-name">{item.name}</span>
                        <span className="homenft-floor-price">{item.floorPrice}</span>
                        <span className="homenft-highest-price">{item.highestPrice}</span>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        );
  }


  return (
      <div className="homenft-bg">
        <div className="homenft-wrapper">
          <div className="homenft-gradient-bg" />

          <div className="homenft-container">
          <div className="homenft-header">
            <h1 className="homenft-title">{t('Home_nft')}</h1>
            <a href="#" className="homenft-learn-more">{t('LearnMore')} »</a>
          </div>

          <div className="homenft-content">
            <div className="homenft-rankings-container">
              <div className="homenft-rankings-column">
                <div className="homenft-table-header">
                  <span className="homenft-header-ranking">{t('Ranking')}</span>
                  <span className="homenft-header-empty"></span>
                  <span className="homenft-header-name"></span>
                  <span className="homenft-header-floor">{t('FloorPrice')}</span>
                  <span className="homenft-header-highest">{t('HighPrice')}</span>
                </div>
                {nftData.slice(0, 6).map((item) => (
                    <div key={item.id} className="homenft-ranking-item">
                      <span className="homenft-rank">{item.id}</span>
                      <img src={TESTAVATAR} alt={item.name} className="homenft-nft-image" />
                      <span className="homenft-nft-name">{item.name}</span>
                      <span className="homenft-floor-price">{item.floorPrice}</span>
                      <span className="homenft-highest-price">{item.highestPrice}</span>
                    </div>
                ))}
              </div>

              <div className="homenft-rankings-column">
                <div className="homenft-table-header">
                  <span className="homenft-header-ranking">{t('Ranking')}</span>
                  <span className="homenft-header-empty"></span>
                  <span className="homenft-header-name"></span>
                  <span className="homenft-header-floor">{t('FloorPrice')}</span>
                  <span className="homenft-header-highest">{t('HighPrice')}</span>
                </div>
                {nftData.slice(6, 12).map((item) => (
                    <div key={item.id} className="homenft-ranking-item">
                      <span className="homenft-rank">{item.id}</span>
                      <img src={TESTAVATAR} alt={item.name} className="homenft-nft-image" />
                      <span className="homenft-nft-name">{item.name}</span>
                      <span className="homenft-floor-price">{item.floorPrice}</span>
                      <span className="homenft-highest-price">{item.highestPrice}</span>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  );
};

export default HomeNftList;
